import { truncate, dateFormater } from '@/filters'

export default {
  name: 'AdminItem',
  filters: {
    truncate,
    dateFormater
  },
  props: {
    admin: {
      type: Object,
      default: {}
    }
  },
  computed: {
    informationData () {
      return this.donation.information || {}
    }
  },
  methods: {
    handleDeleteAdmin () {
      this.$emit('handleDeleteAdmin', this.admin.id)
    },
    handleEditAdmin () {
      this.$emit('handleEditAdmin', this.admin.id)
    }
  }
}
